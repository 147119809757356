<template>
  <div class="cart-button">
    <button @click="updateQuantity(-1)">-</button>
    <div>{{ $store.state.cart[id] }}</div>
    <button @click="updateQuantity(1)">+</button>
  </div>
</template>

<script>
export default {
  name: "QuantityButtons",
  props: {
    id: String,
  },
  methods: {
    updateQuantity(val) {
      this.$store.commit("changeQuantity", { id: this.id, by: val });
    },
  },
};
</script>

<style scoped lang="scss">
.cart-button {
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div {
    margin: 0 1em;
    padding: 0.5em;
    width: 3em;
    font-weight: bold;
    border: 1px solid gray;
  }
  button {
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    overflow: hidden;
    border: 0;
    border-radius: 50%;
    background-color: #f68725;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5em;
  }
}
</style>
