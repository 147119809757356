import { render, staticRenderFns } from "./ProductBox.vue?vue&type=template&id=d0959daa&scoped=true&"
import script from "./ProductBox.vue?vue&type=script&lang=js&"
export * from "./ProductBox.vue?vue&type=script&lang=js&"
import style0 from "./ProductBox.vue?vue&type=style&index=0&id=d0959daa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0959daa",
  null
  
)

export default component.exports