<template>
  <div id="cart-app">
    <div v-show="!$store.state.orderComplete">
      <div class="row pricing-tables">
        <div
          v-for="(product, productKey) in $store.state.products"
          :key="`product-portal-${productKey}`"
          class="col-sm-6 col-xs-12"
        >
          <ProductBox v-bind="product" v-bind:id="productKey" />
        </div>
      </div>
      <div id="row-cart">
        <div id="cart-summary">
          <h3>Cart</h3>
          <table id="cart-table" v-if="cartTotalQuantity > 0">
            <thead>
              <td>Name</td>
              <td>Quantity</td>
              <td>Subtotal</td>
            </thead>
            <template v-for="(cartItem, cartItemKey) in $store.state.cart">
              <tr v-if="cartItem > 0" :key="`cart-item-${cartItemKey}`">
                <td>{{ $store.state.products[cartItemKey].name }}</td>
                <td><QuantityButtons :id="cartItemKey" /></td>
                <td>
                  ${{
                    formatAsCashString(
                      $store.state.cart[cartItemKey] *
                        $store.state.products[cartItemKey].price
                    )
                  }}
                </td>
              </tr>
            </template>
            <tr>
              <td colspan="2">Shipping for {{ cartTotalQuantity }} items:</td>
              <td>${{ shippingCost }}</td>
            </tr>
            <tfoot>
              <td colspan="2">Total</td>
              <td>${{ cartTotalCostWithShipping }}</td>
            </tfoot>
          </table>
          <div v-else class="cart-no-items">
            There are no items in your cart.
          </div>
        </div>
        <div>
          <h3>Checkout</h3>
          <div id="paypal-button-container" :class="{ paypalDisabled }"></div>
        </div>
      </div>
    </div>
    <div id="cart-thanks" v-show="$store.state.orderComplete">
      <h2>ORDER COMPLETE</h2>
      <h3>Thanks for your order!</h3>
      <p>You will receive an email confirmation soon.</p>
      <p class="transaction-details">
        Transaction ID: {{ $store.state.transactionDetails.id }}<br />Time:
        {{ $store.state.transactionDetails.create_time }}
      </p>
      <button
        class="btn btn-common wow fadeInUp animated"
        @click="$store.commit('reset')"
      >
        ORDER AGAIN
      </button>
    </div>
    <portal selector="#portal-nav-cart-icon-desktop">
      <button @click="scrollToCart">
        <i class="lnr lnr-cart"></i>
        <span>{{ cartTotalQuantity }}</span>
      </button>
    </portal>
    <portal selector=".slicknav_menu" :prepend="true">
      <div id="portal-nav-cart-icon-mobile">
        <button @click="scrollToCart">
          <i class="lnr lnr-cart"></i>
          <span>{{ cartTotalQuantity }}</span>
        </button>
      </div>
    </portal>
    <portal selector="#shipping-table">
      <table border="0" cellspacing="0" cellpadding="5">
        <tr>
          <th width="106" scope="col">Quantity</th>
          <th width="108" scope="col">Rate (USD)</th>
        </tr>
        <tr
          v-for="(shippingValue, shippingKey) in $store.state.shipping"
          :key="`shipping-${shippingKey}`"
        >
          <td>{{ shippingKey }}</td>
          <td>${{ formatAsCashString(shippingValue) }}</td>
        </tr>
      </table>
    </portal>
  </div>
</template>

<script>
/* global jQuery */

// import axios from "axios";

import { Portal } from "@linusborg/vue-simple-portal";

import { loadScript } from "@paypal/paypal-js";

import { getRobaneroData } from "./sanity";

import ProductBox from "@/components/ProductBox.vue";
import QuantityButtons from "@/components/QuantityButtons.vue";

function formatAsCashString(value) {
  let cents = Math.round((value % 1) * 100);
  let dollars = Math.floor(value);
  if (cents < 10) {
    cents = "0" + cents;
  }
  return `${dollars}.${cents}`;
}

export default {
  name: "CartApp",
  components: {
    ProductBox,
    QuantityButtons,
    Portal,
  },
  data() {
    return {
      // paypalClientID: "",
      // paypalClientToken: "",
      // paypalScriptTag: null,
      // orderId: "",
      // checkPaypalInt: -1,
      useLivePaypalCredentials:
        window.location.hostname.includes("www") ||
        window.location.hostname === "robanerohotsauce.com",
      paypalDisabled: true,
      heroBGInt: -1,
    };
  },
  watch: {
    cartTotalQuantity: function () {
      this.updatePaypalActiveStatus();
    },
    orderDetails: function () {
      // copy to window so PayPal can get to it
      window.orderDetails = this.orderDetails;
    },
  },
  computed: {
    paypalClientID() {
      if (this.useLivePaypalCredentials) {
        return "AfPFfMneqL4GepdjRarmTdZya8ZuuLuDLiHzFwXi4Vt7HM2GQ-UlX7gcznKkSNulS1EII9ePJHpr6Ekd";
      } else {
        return "AbmdCiyIpEF4ebgLH2PXu75MQgQ8CBriIrf2FtokGAxPk7LMgSjUrS72ltLLkmNEPIMl2wlGtcpnEgMk";
      }
    },
    cartTotalQuantity() {
      return Object.values(this.$store.state.cart).reduce(
        (total, item) => total + item,
        0
      );
    },
    cartTotalItemCost() {
      let cartTotalItemCost = 0;
      for (let i in this.$store.state.cart) {
        cartTotalItemCost +=
          this.$store.state.cart[i] * this.$store.state.products[i].price;
      }
      return formatAsCashString(cartTotalItemCost);
    },
    shippingCost() {
      let itemCount = this.cartTotalQuantity % 8;
      let numPackages = Math.floor(this.cartTotalQuantity / 8);
      let shippingCost = this.$store.state.shipping["1"];
      switch (true) {
        case itemCount === 2:
          shippingCost = this.$store.state.shipping["2"];
          break;
        case itemCount >= 3 && itemCount <= 6:
          shippingCost = this.$store.state.shipping["3-6"];
          break;
        case itemCount >= 7 && itemCount <= 8:
          shippingCost = this.$store.state.shipping["7-8"];
          break;
        default:
          shippingCost = this.$store.state.shipping["1"];
      }
      shippingCost += numPackages * this.$store.state.shipping["7-8"];
      return formatAsCashString(shippingCost);
    },
    cartTotalCostWithShipping() {
      return formatAsCashString(
        Number(this.cartTotalItemCost) + Number(this.shippingCost)
      );
    },
    orderDetails() {
      let orderDetails = {
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: 0,
              breakdown: {
                item_total: {
                  currency_code: "USD",
                  value: 0,
                },
                shipping: {
                  currency_code: "USD",
                  value: 0,
                },
              },
            },
            items: [],
          },
        ],
      };

      orderDetails.purchase_units[0].amount.value =
        this.cartTotalCostWithShipping;
      orderDetails.purchase_units[0].amount.breakdown.item_total.value =
        this.cartTotalItemCost;
      orderDetails.purchase_units[0].amount.breakdown.shipping.value =
        this.shippingCost;

      for (let i in this.$store.state.cart) {
        if (this.$store.state.cart[i] > 0) {
          orderDetails.purchase_units[0].items.push({
            sku: this.$store.state.products[i].sku,
            name: this.$store.state.products[i].name,
            quantity: String(this.$store.state.cart[i]),
            // description: this.$store.state.products[i].description,
            unit_amount: {
              currency_code: "USD",
              value: this.$store.state.products[i].price,
            },
            category: "PHYSICAL_GOODS",
          });
        }
      }
      return orderDetails;
    },
  },
  async created() {
    // axios.get(`/paypal.php${this.useLivePaypalCredentials ? "?live" : ""}`).then((res) => {
    // this.paypalClientToken = res.data.client_token;
    // this.paypalClientID = res.data.client_id;
    // });
    const config = await getRobaneroData();
    this.$store.commit("initializeCart", config);
    loadScript({ "client-id": this.paypalClientID })
      .then((paypal) => {
        console.log("PayPal JS SDK script load success");
        this.paypalInit(paypal);
      })
      .catch((err) => {
        console.error("PayPal JS SDK script load failure", err);
      });

    // const configFilename =
    //   process.env.NODE_ENV === "development"
    //     ? "config-dev.json"
    //     : "config.json";
    // axios
    //   .get(`/${configFilename}?r=${Math.random()}`)
    //   .then((res) => {
    //     console.log("Loaded config", res.data);
    //     this.$store.commit("initializeCart", res.data);
    //     loadScript({ "client-id": this.paypalClientID })
    //       .then((paypal) => {
    //         console.log("PayPal JS SDK script load success");
    //         this.paypalInit(paypal);
    //       })
    //       .catch((err) => {
    //         console.error("PayPal JS SDK script load failure", err);
    //       });
    //   })
    //   .catch((err) => console.error("Error getting products", err));
  },
  mounted() {
    window.requestAnimationFrame(this.updateHeroBG);
  },
  methods: {
    updateHeroBG() {
      document
        .getElementById("hero-area")
        .style.setProperty(
          "--top-offset",
          Math.max(
            0,
            Math.min(
              50,
              window.scrollY /
                parseInt(document.getElementById("hero-area").clientHeight) /
                0.02
            )
          ) + "%"
        );
      window.requestAnimationFrame(this.updateHeroBG);
    },
    formatAsCashString,
    scrollToCart() {
      jQuery("html, body").animate(
        {
          scrollTop:
            jQuery("#row-cart").offset().top -
            jQuery("nav.navbar").outerHeight(),
        },
        1000
      );
    },
    paypalInit(paypal) {
      paypal
        .Buttons({
          // Sets up the transaction when a payment button is clicked
          createOrder: function (data, actions) {
            console.log(window.orderDetails);
            return actions.order.create(window.orderDetails);
          },
          onCancel: function () {
            // do nothing
          },
          onError: function (err) {
            window.alert(
              "Sorry, there has been an error, please try again.",
              err
            );
          },
          onInit: function (data, actions) {
            actions.disable();
            window.paypalButtonActions = actions;
            window.cartApp.$children[0].updatePaypalActiveStatus();
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(function (orderData) {
              // Successful capture! For dev/demo purposes:
              console.log(
                "Capture result",
                orderData,
                JSON.stringify(orderData, null, 2)
              );
              var transaction =
                orderData.purchase_units[0].payments.captures[0];
              // alert(
              //   "Transaction " +
              //     transaction.status +
              //     ": " +
              //     transaction.id +
              //     "\n\nSee console for all available details"
              // );
              window.cartApp.$store.commit("setOrderComplete", transaction);
            });
          },
        })
        .render("#paypal-button-container");
    },
    updatePaypalActiveStatus() {
      if (window.paypalButtonActions) {
        if (this.cartTotalQuantity > 0) {
          window.paypalButtonActions.enable();
          this.paypalDisabled = false;
        } else {
          window.paypalButtonActions.disable();
          this.paypalDisabled = true;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#nav-logo-wrapper {
}
#paypal-button-container {
  background-color: #fff;
  padding: 2em 2em 0.5em 2em;
}
.paypalDisabled {
  opacity: 0.5;
}
#cart-app {
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pricing-tables,
.pricing-tables > div,
.pricing-tables > div > div {
  display: flex;
  align-items: stretch;
  &.pricing-table {
    align-items: center;
    width: 100%;
  }
}
.pricing-tables > div {
  margin-bottom: 1.5em;
}
.pricing-details {
  width: 100%;
}
#row-cart {
  width: 100%;
  padding: 2em 0;
  color: #fff;
  @media only screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    > * {
      width: calc(50% - 1em);
      display: flex;
      flex-direction: column;
      > *:not(h3) {
        flex-grow: 1;
      }
    }
  }
  @media only screen and (max-width: 959px) {
    display: block;
    > *:last-child {
      margin-top: 2em;
    }
  }
  table {
    width: 100%;
    background-color: #fff;
    @media only screen and (max-width: 375px) {
      font-size: 0.8em;
    }
    thead {
      font-weight: bold;
    }
    tr td {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #f68725;
      }
    }
    td {
      padding: 1em 0.5em;
      color: #000;
      text-align: center;
      &:first-child {
        text-align: left;
        padding-left: 1.5em;
        &:before {
          left: 1.5em;
        }
      }
      &:last-child {
        text-align: right;
        padding-right: 1.5em;
        &:before {
          right: 1.5em;
        }
      }
    }
    tfoot {
      background-color: #f68725;
      td {
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
#cart-thanks {
  margin: 0 auto 3em auto;
  padding-bottom: 2em;
  min-width: 300px;
  width: 50vw;
  max-width: 600px;
  background-color: #fff;
  * {
    text-align: center;
  }
  h2 {
    padding: 1.25em 0.75em 0.75em 0.75em;
    background-color: #f1f1f1;
    color: #000;
    font-size: 2em;
  }
  h3 {
    padding: 0.5em 1em;
    font-weight: 100;
    color: #f68725;
  }
  p {
    margin: 0;
    padding: 0 1em 1em 1em;
    color: #000;
  }
  .transaction-details {
    line-height: 1.5;
    padding-bottom: 2em;
    font-size: 0.8em;
    color: gray;
  }
  .btn {
    display: block;
    cursor: pointer;
    margin: 0 auto;
  }
}
.nav-item-cart-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .slicknav_menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.slicknav_btn {
  background: rgba(255, 255, 255, 0.2);
  border: 0;
  .slicknav_icon-bar {
    height: 1px;
    margin-top: 5px !important;
    &:first-child {
      margin-top: 0 !important;
    }
    background: #fff;
  }
}
.slicknav_icon {
  transform: translate(0, 1px);
  display: block;
}
.slicknav_nav {
  position: absolute;
  top: 100%;
  right: 0px;
}
@media only screen and (max-width: 991px) {
  .slicknav_menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
#portal-nav-cart-icon {
  &-desktop,
  &-mobile {
    display: none;
    cursor: pointer;
    button {
      display: flex;
      align-items: center;
      padding: 0.25em;
      border: 0;
      background: transparent;
      appearance: none;
      color: #fff;
      cursor: pointer;
      i {
        font-size: 2em;
        margin-right: 0.33em;
        transform: translate(0px, -2px);
      }
    }
  }
}
#portal-nav-cart-icon-desktop {
  @media only screen and (min-width: 992px) {
    display: block;
    margin-left: 0.5em;
    button {
      span {
        transform: translate(0, 1px);
      }
    }
  }
}
#portal-nav-cart-icon-mobile {
  @media only screen and (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em;
    height: 35px;
    background-color: rgba(255, 255, 255, 0.2);
    button {
      transform: translate(0, 1px);
      i {
        font-size: 1.2em;
      }
    }
  }
}
.navbar {
  @media only screen and (max-width: 375px) {
    padding: 0;
  }
}
.navbar-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 991px) {
    flex-direction: row-reverse;
  }
}
nav.navbar {
  .navbar-brand {
    position: static;
    padding: 0;
    margin: 0;
    width: auto !important;
    height: initial !important;
    // @media only screen and (max-width: 991px) {
    padding: 10px;
    // }
    img {
      @media only screen and (min-width: 1200px) {
        width: 300px;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 250px;
      }
      @media only screen and (max-width: 991px) {
        width: 160px !important;
      }
    }
  }
}
#hero-area {
  --top-offset: 0;
  position: relative;
  background-image: none;
  img.hero-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(0, var(--top-offset, 0));
    // transition: transform 0.25s ease;
  }
  .btn {
    margin: 0 !important;
  }
}
</style>
