import { createClient } from "@sanity/client";

import { toHTML, uriLooksSafe } from "@portabletext/to-html";

import htm from "htm";
import vhtml from "vhtml";
const html = htm.bind(vhtml);

export const sanityClient = createClient({
  projectId: "ii0heni5",
  dataset: "production",
  useCdn: !["localhost", "127.0.0.1"].includes(window.location.hostname), // set to `true` to fetch from edge cache
  apiVersion: "2022-01-12", // use current date (YYYY-MM-DD) to target the latest API version
});

export async function getRobaneroData() {
  const docs = await sanityClient.fetch(
    `*[ _type == "robaneroConfig" || _type == "robaneroProduct" ]`
  );
  const config = docs.filter((doc) => doc._type === "robaneroConfig")[0];
  var shipping = {
    1: config.shipping1,
    2: config.shipping2,
    "3-6": config.shipping36,
    "7-8": config.shipping78,
  };

  const products = docs.filter((doc) => doc._type === "robaneroProduct");
  return { shipping, products };
}

export function sanityTextToHTML(text) {
  return toHTML(text, {
    marks: {
      link: ({ children, value }) => {
        // ⚠️ `value.href` IS NOT "SAFE" BY DEFAULT ⚠️
        // ⚠️ Make sure you sanitize/validate the href! ⚠️
        const href = value.href || "";

        if (uriLooksSafe(href)) {
          const rel = href.startsWith("/") ? undefined : "noreferrer noopener";
          return html`<a href="${href}" rel="${rel}">${children}</a>`;
        }

        // If the URI appears unsafe, render the children (eg, text) without the link
        return children;
      },
    },
  });
}
