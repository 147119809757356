import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: "robanero",
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    products: {},
    shipping: {},
    cart: {},
    orderComplete: false,
    transactionDetails: {},
  },
  mutations: {
    initializeCart(state, { shipping, products }) {
      state.products = products.sort((a, b) => a.orderRank > b.orderRank ? 1 : -1);
      state.shipping = shipping;
    },
    changeQuantity(state, payload) {
      if (!state.cart[payload.id]) {
        Vue.set(state.cart, payload.id, 0);
      }
      Vue.set(state.cart, payload.id, state.cart[payload.id] + payload.by);
    },
    setOrderComplete(state, payload) {
      state.orderComplete = true;
      state.transactionDetails = payload;
      for (let c in state.cart) {
        Vue.set(state.cart, c, 0);
      }
    },
    reset(state) {
      for (let c in state.cart) {
        Vue.set(state.cart, c, 0);
      }
      state.orderComplete = false;
      state.transactionDetails = {};
    },
  },
  plugins: [vuexLocal.plugin],
});
