import Vue from "vue";
import CartApp from "./CartApp.vue";
import store from "./store.js";

Vue.config.productionTip = false;

window.cartApp = new Vue({
  store,
  render: (h) => h(CartApp),
}).$mount("#cart-app");
