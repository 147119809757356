<template>
  <div class="pricing-table">
    <div class="pricing-details">
      <h2>{{ name }}</h2>
      <span v-if="price >= 0">${{ price }}</span>
      <p v-if="price >= 0">+ Shipping</p>
      <p v-html="descriptionHTML" />
    </div>
    <!--<form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
				<input type="hidden" name="cmd" value="_s-xclick">
				<input type="hidden" name="hosted_button_id" value="45GLCCQ8QJ5YY">
				<input type="image" src="img/buynow.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
				<img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
				</form>-->
    <div v-if="stock === 0" class="plan-button">
      <strong>OUT OF STOCK &gt;:(</strong>
    </div>
    <button
      v-else-if="stock < 0"
      class="btn btn-common wow fadeInUp animated"
      @click="updateQuantity(1)"
    >
      PRE-ORDER
    </button>
    <button
      v-else
      class="btn btn-common wow fadeInUp animated"
      @click="updateQuantity(1)"
    >
      ADD TO CART
    </button>
  </div>
</template>

<script>
import { sanityTextToHTML } from '../sanity';

export default {
  name: "ProductBox",
  props: {
    id: [String, Number],
    name: String,
    description: Array,
    price: Number,
    stock: Number,
  },
  computed: {
    descriptionHTML() {
      return sanityTextToHTML(this.description);
    },
  },
  methods: {
    updateQuantity(val) {
      this.$store.commit("changeQuantity", { id: this.id, by: val });
      this.$parent.scrollToCart();
    },
  },
};
</script>

<style scoped lang="scss">
.pricing-table {
  flex-direction: column;
  > *:last-child {
    margin-top: auto;
  }
}
.btn {
  display: inline-block;
  width: auto;
  cursor: pointer;
}
</style>
